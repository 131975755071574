const $ = $jq183;

function spNavBtn() {
    let navBar = $('.header-nav-btn-sp__bar');
    let navWrap = $('.header-nav-wrap');
    $('.js-header-nav-btn-sp').on('click', function(){
        if(!($(this).hasClass('is-menu-open'))){
            navWrap.fadeIn();
            navBar.addClass('is-menu-open').removeClass('is-menu-close');
            $(this).addClass('is-menu-open');
            $('html').addClass('is-view-lock');
        } else if($(this).hasClass('is-menu-open')){
            navWrap.fadeOut();
            navBar.removeClass('is-menu-open').addClass('is-menu-close');
            $(this).removeClass('is-menu-open');
            $('html').removeClass('is-view-lock'); 
        }
    });

    let secName = ['message','business','president','topics','contact','company'];
    for(let i = 0;i < secName.length;i++) {
        $('.' + secName[i]).on('click', function(){
            let adjust = $('.header').outerHeight();
            let posSec = $('#' + secName[i]).offset().top - 80
            let speed = 800;
            $(this).addClass('is-current');
            $('.header-nav__link').addClass('is-no-clr');
            setTimeout(function(){
                $('.header-nav__link').removeClass('is-no-clr');
            },1000);
            setTimeout(function(){
                $('body,html').animate({scrollTop:posSec}, speed, 'easeInExpo');
                
            },200);
           
        });
    }

    $(document).on('click', '.js-header-nav__link', function(){
        $('.header-nav-btn-sp').trigger('click');
    });
    
};

function spNavResize(pc,sp) {
    let navBar = $('.header-nav-btn-sp__bar');
    let navWrap = $('.header-nav-wrap');
    let navBtnSp = $('.header-nav-btn-sp');
    if(pc){
        navWrap.css('display', 'block');
        $('.header-nav__link').removeClass('js-header-nav__link');
        
    } else if(sp) {
        navWrap.css('display', 'none');
        navBar.removeClass('is-menu-open');
        navBtnSp.removeClass('is-menu-open');
        $('.header-nav__link').addClass('js-header-nav__link');
    }
    $('html').removeClass('is-view-lock'); 
}

function navCurrent(){
    let winScr = $(window).scrollTop();
    let ttlId = ['#message','#business','#president','#topics','#contact','#company'];
    let navLink = $('.header-nav__link');
    //console.log(winScr + 100);
    for(let i=0;i<ttlId.length;i++){
        let ttlPos = $(ttlId[i]).position().top;
        if(ttlPos - 200 < winScr){
            navLink.removeClass('is-current');
            navLink.eq(i).addClass('is-current');
        } else if(winScr < $(ttlId[0]).position().top - 300) {
            navLink.removeClass('is-current');
        }
    }
    let docHeight = $(document).innerHeight(),
    windowHeight = $(window).innerHeight(), 
    pageBottom = docHeight - windowHeight; 
    if(pageBottom <= $(window).scrollTop()) {
        $('.header-nav__link').removeClass('is-current');
        $('.header-nav__link').eq(ttlId.length - 1).addClass('is-current');
    }
    
}



$(function(){
    spNavBtn();
});

$(window).on('load resize', function(){
    let pc = window.matchMedia("(min-width: 1080px)").matches;
    let sp = window.matchMedia("(max-width: 1079px)").matches;
    spNavResize(pc,sp);
});

$(window).on('scroll', function(){
    navCurrent()
});


